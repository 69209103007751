import './Toturial.css';
import { 
  Card,
  InlineStack,
  Text,
  Icon,
  Pagination,
  MediaCard,
  VideoThumbnail
 } from "@shopify/polaris"
 import {
  MobileHorizontalDotsMajor
} from '@shopify/polaris-icons';
import { useState } from 'react';
import type { ToturialDto } from '../../dto';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface ToturialProp {
  title?: string;
  subTitle?: string;
  toturials: ToturialDto[]
}

export default function Toturial(props: ToturialProp) {
  const [selectToturial, setSelectToturial] = useState<number>(0);

  return (
    <div className="toturial">
      {props.toturials.length > 1 ?
      <Card>
        {props.title &&
        <div className="mb-1">
          <InlineStack align="space-between">
            <Text as="p" fontWeight="bold" variant='headingMd'>{props.title}</Text>
            <Icon
              source={MobileHorizontalDotsMajor}
              tone="base"
            />
          </InlineStack>
        </div>
        }
        {props.subTitle &&
          <div className="mb-1">
            <Text as="p">{props.subTitle}</Text>
          </div>  
        }
        <Carousel
          centerMode={true}
          centerSlidePercentage={90}
          showArrows={false}
          selectedItem={selectToturial}
          showIndicators={false}
          showStatus={false}
        >
        {props.toturials.map((toturial, index: number) => {
            return (
              <div className='toturial-vid-container'>
                <div key={index} className='toturial-vid'>
                  <MediaCard
                    portrait
                    title={toturial.title}
                    primaryAction={{
                      content: 'Learn more',
                      onAction: () => window.open(toturial.link, '_blank', 'noopener,noreferrer'),
                    }}
                    description={toturial.des}
                    popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
                  >
                    <VideoThumbnail
                      videoLength={toturial.vidLength}
                      thumbnailUrl={toturial.thumbnailUrl}
                      onClick={() => {
                        window.open(toturial.link, '_blank', 'noopener,noreferrer')
                      }}
                    />
                  </MediaCard>
                </div>
              </div>
            )
          })}
      </Carousel>
        {props.toturials.length > 1 &&
        <div className="mt-2">
          <InlineStack blockAlign="center" >
            <Pagination
              hasPrevious
              onPrevious={() => {
                if (selectToturial === 0) {
                  return
                }
                setSelectToturial(selectToturial-1)
              }}
              hasNext
              onNext={() => {
                if (selectToturial+1 === props.toturials.length) {
                  return
                }
                setSelectToturial(selectToturial+1)
              }}
            />
            <p className="mx-1">{selectToturial+1}/{props.toturials.length}</p>
          </InlineStack>
        </div>
        }
      </Card>
      : 
      <div className="toturial-vid-container">
      {props.toturials.map((toturial, index: number) => {
        return (
          <div className={`toturial-vid ${selectToturial === toturial.index ? 'show' : undefined}`} key={index}>
            <MediaCard
              portrait
              title={toturial.title}
              primaryAction={{
                content: 'Learn more',
                onAction: () => window.open(toturial.link, '_blank', 'noopener,noreferrer'),
              }}
              description={toturial.des}
              popoverActions={[{content: 'Dismiss', onAction: () => {}}]}
            >
              <VideoThumbnail
                videoLength={toturial.vidLength}
                thumbnailUrl={toturial.thumbnailUrl}
                onClick={() => {
                  window.open(toturial.link, '_blank', 'noopener,noreferrer')
                }}
              />
            </MediaCard>
          </div>
        )
      })}
    </div>
      }
    </div>
  )
}

import './progress.css'
import {
  BlockStack,
  Card,
  ProgressBar,
  Text
} from "@shopify/polaris";
import CircleTick from '../../assets/svg/circle_tick';
import CircleUnTick from '../../assets/svg/circle_untick';
import { PROGRESS } from "../../dto";
import PluginChat from '../../config/pluginChat';

interface CreatePixelStep {
  step: number,
  label: string,
  progress: number
}

interface ProgressProps {
  progress: PROGRESS,
  onClick: any
}

const steps: CreatePixelStep[] = [
  {
    step: 1,
    label: "Activate our app in your theme",
    progress: 25
  },
  {
    step: 2,
    label: "Enter your pixel’s detail",
    progress: 50
  },
  {
    step: 3,
    label: 'Add Madgic script in the settings -> checkout',
    progress: 75
  },
  {
    step: 4,
    label: 'Make sure the pixel is working correctly',
    progress: 100
  }
]

export default function Progress({progress, onClick} : ProgressProps) {

  const renderStepDone = (progress: PROGRESS) => {
    switch(progress) {
      case PROGRESS._0:
        return '0'
      case PROGRESS._25:
        return '1'
      case PROGRESS._50:
        return '2'
      case PROGRESS._100:
        return '4'
      default:
        return ''
    }
  }

  return (
    <div className='mb-2'>
      <Card padding="0">
        <BlockStack>
          <div className="progress p-2">
            <Text variant="headingMd" as="p" >Get your first pixel</Text>
            <div className="progress-status">
              <p style={{width: 'minWidth'}}>{renderStepDone(progress)} of 4 steps completed</p>
              <ProgressBar progress={progress} tone="primary" size="small" />
            </div>
          </div>
        </BlockStack>
        <BlockStack>
          <div className="p-2">
            {steps.map((step) => {
              return (
                <div key={step.label} className="py-1">
                  <div className="progress-step" style={{"cursor": 'pointer'}} onClick={onClick}>
                    <div className="mr-1" style={{ display: 'flex', alignItems: 'center'}} >
                      {progress >= step.progress ? <CircleTick /> :<CircleUnTick />}
                    </div>
                    <Text as="p" fontWeight="medium">{step.label}</Text>
                  </div>
                </div>
              )
            })}
          </div>
        </BlockStack>
        <BlockStack>
          <div className="p-2" style={{fontStyle: 'italic'}}>
            <Text as="p">
              That's it! If you have any questions along the way, don't hesitate to ask.{" "}
              <span 
                style={{color: 'var(--p-color-text-link)'}}
                onClick={() => PluginChat.openChat()}
              >
                We're here to help.
              </span>
            </Text>
          </div>
        </BlockStack>
    </Card>
    </div>
  )
}

import {type InfoPixelBuffer, InfoPixelBufferType } from "./dashboard"

export const getSamplePIxelInfor = (): InfoPixelBuffer[] => {
  return Object.values(InfoPixelBufferType).map((value) => {
    return {
        Type: value,
        Title: getPixelInforLabel(value as InfoPixelBufferType),
        Datasets: [],
        TotalEvents: 0
      }
  });
}

const getPixelInforLabel = (type: InfoPixelBufferType) => {
  if (type === InfoPixelBufferType.AddToCart) return 'Add To Cart';
  if (type === InfoPixelBufferType.InitiateCheckout) return 'Initiate Checkout';
  if (type === InfoPixelBufferType.Purchase) return 'Purchase';
  if (type === InfoPixelBufferType.ViewContent) return 'View Content';
  return ''
}
import axios from "axios";
import api from '../http/axios-instance'
import { PixelDashboard } from "../dto";
import config from "../config";
import { InsightDto } from "../dto";

interface GetInfoPixelBufferParams {
  pixelId?: number,
  startTime: Date,
  endTime: Date,
  timezone: number
}

export const GetInfoPixelBuffer = async (params: GetInfoPixelBufferParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars 
  const {pixelId, startTime, endTime, timezone} = params
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_END_POINT}/frontend/analytics`
      ,{
        params: {
          shop: localStorage.getItem('shop'),
          pixelID: pixelId,
          startDate: startTime,
          endDate: endTime,
          timeZone: timezone 
        }
      }
    )
    return data
  } catch (error) {
    return {
      data: undefined,
    };
  }
};

export const createRating = async (feadback:string) => {
  const { data } = await api.post(
    `${process.env.REACT_APP_END_POINT}/frontend/CreateRating`,
    {
      ShopID: localStorage.getItem('shopID'),
      Feedback: feadback,
    },
  );
  return data;
};

export const getPixels = async (): Promise<PixelDashboard[] | null> => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_END_POINT}/frontend/pixels`,{
      params: {
        shop: config.shop,
      }
    });
    const pixelList: PixelDashboard[] = res.data
    
    return pixelList
  } catch(err) {
    return null
  }
}

export const updateTimezone = async (timezone: number, timezoneName: string) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_END_POINT}/frontend/UpdateTimezone`,
      {
        Shop: localStorage.getItem('shop'),
        timezone,
        timezoneName,
      },
    )
    return data
  } catch (err) {
    return null
  }
}

export const getPost = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_END_POINT}/frontend/posts`, {
      params: {
        shop: config.shop,
      }
    })
    const postList: InsightDto[] = res.data;
    return postList 
  } catch(err) {
    return []
  }
}

import { BlockStack, Button, Page } from "@shopify/polaris";
import "./tiktok-shop.css";
import React from "react";
import Navigation from "../../components/navigation/Navigation";
import TikTokShopSvg from "../../assets/img/Tiktok-Shop-Banner.png";

const TiktokShop = () => {
  const handleCick = () => {
    window.open("https://forms.gle/Mf2cCs8X2dKKNYmG8", "_blank");
  };
  return (
    <div className="tiktok-shop">
      <Page fullWidth>
        <BlockStack>
          <Navigation />
        </BlockStack>
        <div className="container-shop">
          <div className="content">
            <div className="content-title">
              Achieve your desired revenue by expanding your customer base on
              TikTok Shop
            </div>
            <div
              className="text-content content-des"
              style={{ marginTop: "32px" }}
            >
              Make your products available to every person who shops on TikTok.
            </div>
            <div className="text-content content-des">
              Reach a larger customer base by leveraging the TikTok Shopping
              Center to promote your products.
            </div>
            <div className="text-content content-des">
              Manage and optimize your product listings directly through
              Shopify.
            </div>
            <div style={{ marginTop: "35px" }}>
              <Button variant="primary" onClick={handleCick}>
                Early Access Is Now Open !
              </Button>
            </div>
          </div>
          <div className="image">
            <img src={TikTokShopSvg}  onClick={handleCick} style={{ marginRight: "45px" }} />
          </div>
        </div>
      </Page>
    </div>
  );
};

export default TiktokShop;

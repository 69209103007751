import './dashboard.css'
import {
  Page,
  BlockStack,
  Grid,
  Select,
  InlineStack,
  Icon,
  SkeletonPage,
  Card,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useEffect, useState } from 'react';
import Navigation from '../../components/navigation/Navigation';
import { RefreshMajor } from "@shopify/polaris-icons";
import OverviewData from '../../components/overviewData/OverviewData';
import Feedback from '../../components/feedback/Feedback';
import RecommendApp from '../../components/recommendApp/RecommendApp';
import Insight from '../../components/insight/Insight';
import PurchaseEvent from '../../components/purchaseEvent/PurchaseEvent';

import { GetInfoPixelBuffer, getPixels, updateTimezone } from '../../api/dashboard';
import  { type InfoPixelBuffer, QUERY_DASHBOARD_DATE, type PixelDashboard } from '../../dto';
import timezones from '../../config/timezone.config';
import { getPreviousTime, getTimeZoneNumber } from '../../utils';
import { getSamplePIxelInfor } from '../../dto/sample-data.dashboard';

export interface SelecDashboardDate {
  start: Date;
  end: Date
}

const getInitTimezone = () => {
  return timezones.find((timezone) => {
    return timezone.value === localStorage.getItem('timezone')
  })?.value
}

export default function Dashboard() {
  const [infoPixelBuffer, setInfoPixelBuffer] = useState<InfoPixelBuffer[]>([])
  const [timezone, setTimezone] = useState<string>(getInitTimezone() || "Default timezone");
  const [selected, setSelected] = useState<QUERY_DASHBOARD_DATE>(QUERY_DASHBOARD_DATE.TODAY);
  const [selectedDates, setSelectedDates] = useState<SelecDashboardDate>({
    start: new Date(new Date(Date.now()).setUTCHours(0,0,0,0)),
    end: new Date(),
  });
  const [pixelList, setPixelList] = useState<PixelDashboard[]>([]);
  const [selectedPixel, setSelectedPixel] = useState<PixelDashboard | null>(null)
  const [loading, setLoading] = useState<boolean>(true);
  const [isFirstLoading, setFirstLoading] =useState<boolean>(true);

  useEffect(() => {
    dedayFetchPixel()
    setFirstLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selected !== QUERY_DASHBOARD_DATE.CUSTOM) {
      if (selectedPixel) {
        delayFetchDashboard();
      } else {
        setDataWhenNoAPIPixel();
      }
    } 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, timezone, selectedPixel])

  useEffect(() => {
    if (selected !== QUERY_DASHBOARD_DATE.CUSTOM) {
      setSelectedDates({
        start: getPreviousTime(selected).startTime,
        end: getPreviousTime(selected).endTime,
      })
    } else {
      setSelectedDates({
        start: getPreviousTime(QUERY_DASHBOARD_DATE.LAST_7).startTime,
        end: getPreviousTime(QUERY_DASHBOARD_DATE.LAST_7).endTime,
      })
    }
 }, [selected])

  const handleSelectCustomDate = () => {
    delayFetchDashboard();
  }
  
  const resetCustomDate = () => {
    setSelectedDates({
      start: new Date(new Date(Date.now()).setUTCHours(0,0,0,0)),
      end: new Date(),
    })
  }

  const fetchInfoPixelBuffer = async () => {
    setLoading(true)
    const data =  await GetInfoPixelBuffer({
      startTime: selectedDates.start,
      endTime: selectedDates.end,
      timezone: getTimeZoneNumber(timezone),
      pixelId: selectedPixel?.Id
    });
    setInfoPixelBuffer(data);

    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const fetchPixels = async () => {
    const res = await getPixels();
    if (res?.length) {
      setPixelList(res);
      setSelectedPixel(res?.[0])
    }
  }

  const dedayFetchPixel = () => {
    setTimeout(async () => {
      await fetchPixels()
    }, 100)
  }

  const delayFetchDashboard = () => {
    setTimeout(async () => {
      await fetchInfoPixelBuffer()
    }, 100)
  }

  const setDataWhenNoAPIPixel = () => {
    setInfoPixelBuffer(getSamplePIxelInfor())
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const handleUpdateTimezone = async (timezoneName: string) => {
    const time = timezones.find((timezone) => timezone.value === timezoneName)?.time;
    setTimezone(timezoneName);
    await updateTimezone(Number(time), timezoneName)
  }

  return (
    <div className="dashboard">
      <Page fullWidth>
        <BlockStack>
          <Navigation />
        </BlockStack>
        <div className="my-1">
          <InlineStack align="end">
            <div className="mx-1">
              <Select
                label=""
                value={timezone} 
                options={
                  timezones.map(({label, value}) => {
                    return {
                      label: label,
                      value: value
                    }
                  })
                }
                onChange={(value) => handleUpdateTimezone(value)}
              />
            </div>
            <button className="refresh-data-btn" onClick={() => fetchInfoPixelBuffer()}>
              <Icon
                source={RefreshMajor}
                tone="base"
              />
              Refresh Data
            </button>
          </InlineStack>
        </div>
        {loading && isFirstLoading ?
          <Skeleton />
          :
          <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 12, xl: 12}}>
            <OverviewData 
              infoPixelBuffer={infoPixelBuffer}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              selected={selected}
              setSelected={setSelected}
              handleSelectCustomDate={handleSelectCustomDate}
              resetCustomDate={resetCustomDate}
              pixelList={pixelList}
              selectedPixel={selectedPixel}
              setSelectedPixel={setSelectedPixel}
              loading={loading}
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 9, xl: 9}}>
            <PurchaseEvent 
              infoPixelBuffer={infoPixelBuffer}
              selectedDates={selectedDates}
              loading={loading}
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 3, xl: 3}}>
            <Feedback />
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 4, xl: 4}}>
            <Insight />
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 8, xl: 8}}>
            <RecommendApp />
          </Grid.Cell>
        </Grid>
        }
      </Page>
    </div>
  );
}

function Skeleton() {
  return (
    <SkeletonPage fullWidth primaryAction>
      <Grid>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 12, xl: 12}}>
          <Card>
            <SkeletonBodyText />
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 9, xl: 9}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 3, xl: 3}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 4, xl: 4}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 8, xl: 8}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
      </Grid>
    </SkeletonPage>
  );
}

import { PixelDashboard } from "../../dto";
import { 
  type Dispatch,
  type SetStateAction,
  useState,
  useCallback 
} from 'react';
import { Badge, InlineStack, Popover, RadioButton, Text } from "@shopify/polaris";


interface PixelOverViewProps {
  pixelList: PixelDashboard[];
  selectedPixel: PixelDashboard | null;
  setSelectedPixel: Dispatch<SetStateAction<PixelDashboard | null>>
}

export default function PixelOverview(props: PixelOverViewProps) {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <div onClick={togglePopoverActive} style={{cursor: 'pointer'}}>
      <Text as='p' variant='headingSm'>{props.selectedPixel?.PixelID || 0}</Text>
    </div>
  );

  if (!props.selectedPixel) {
    return <Text as='p' variant='headingSm'>0</Text>
  }

  return (
    <Popover
    active={popoverActive}
    activator={activator}
    autofocusTarget="first-node"
    onClose={togglePopoverActive}
    fluidContent={true}
  >
    <div className='p-2' style={{maxWidth: '720px'}}>
      {props.pixelList.map((pixel) => {
        return (
          <InlineStack align="space-between" blockAlign="center">
            <RadioButton
              id={pixel?.PixelID}
              label={pixel?.PixelID}
              value={pixel?.PixelID}
              checked={props.selectedPixel?.PixelID === pixel?.PixelID}
              name={pixel?.PixelID}
              onChange={(checked, value) => {
                props.setSelectedPixel((props.pixelList.find((pixel) => pixel.PixelID === value) || null))
              }}
            />
            <div className="ml-2">
              {pixel?.Status ? (
                <Badge size="small" tone="success">
                  Active
                </Badge>
              ) : (
                <Badge size="small">Disable</Badge>
              )}
            </div>
          </InlineStack>
        )
      })}
    </div>
  </Popover>
  )
}

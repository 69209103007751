import type { DeletePixelPayload, PixelDetail, PixelDetailPayload } from "../dto/pixel.dto";
import api from "../http/axios-instance";

export const GetPixelList = async (): Promise<PixelDetail[]> => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_END_POINT}/FrontEnd/GetAllPixels`
      ,{
        params: {
          shop: localStorage.getItem('shop'),
        }
      }
    )
    return data
  } catch (error) {
    return []
  }
};

export const GetPixelByID = async (id: string): Promise<PixelDetail | null> => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_END_POINT}/FrontEnd/GetPixel`
      ,{
        params: {
          shop: localStorage.getItem('shop'),
          pixelID: id
        }
      }
    )
    return data
  } catch (error) {
    return null
  }
};

export const CreatePixelRequest = async (payload: PixelDetailPayload): Promise<{isSuccess: boolean, Messenger?: string}> => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_END_POINT}/FrontEnd/CreatePixel`
      ,payload
    )
    return {
      isSuccess: data.IsSuccess,
      Messenger: data.Messenger
    }
  } catch (error: any) {
    return {
      isSuccess: false,
    }
  }
};

export const DeletePixelRequest = async (pixel: DeletePixelPayload): Promise<any> => {
  try {
    const response = await api.post(
      `${process.env.REACT_APP_END_POINT}/FrontEnd/DeletePixel`
      ,{
        id: pixel.ID,
        ShopID: pixel.ShopID,
        Shop: localStorage.getItem('shop'),
      }
    )
    return response
  } catch (error) {
    return []
  }
};

export const checkEmbededApp = async (): Promise<any> => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_END_POINT}/FrontEnd/checkappembed?shop=${localStorage.getItem('shop')}`
    )
    return response
  } catch (error) {
    return null
  }
};

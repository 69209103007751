import './insight.css';
import { 
  Card,
  InlineStack,
  Text,
  Link,
  BlockStack
 } from "@shopify/polaris";

import NotiTickSvg from '../../assets/svg/noti_tick.svg';
import NotiUntickSvg from '../../assets/svg/noti_untick.svg';
import { useEffect, useState } from 'react';
import { type InsightDto } from '../../dto';
import { getPost } from '../../api/dashboard';

export default function Insight() {
  const [insights, setInsights] = useState<InsightDto[]>([])

  useEffect(() => {
    fetchInsights()
  }, []);

  const fetchInsights = async () => {
    const insights = await getPost();
    setInsights(insights)
  }
  
  return (
    <Card padding="0">
      <div className="insight-container p-2">
        <div className="mb-1">
          <Text as="p" variant='headingLg' fontWeight='semibold'>Insights and Inspiration</Text>
        </div>
        <div className='insight-posts'>
          {insights.map((insight, index) => {
            return (
              <a 
                href={insight.Link} 
                rel="noreferrer" 
                target="_blank" 
                key={index} 
                className="insight my-1 pb-2"
              >
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                  <img src={index === 0 ? NotiTickSvg : NotiUntickSvg} alt="tick" className="mr-1"/>
                  <BlockStack>
                    <Text as="p" variant="headingSm">{insight.Title}</Text>
                    <Text as="p" variant="bodySm" tone="subdued" >{insight.Description}</Text>
                  </BlockStack>
                </div>
                <div className="my-1">
                  <InlineStack align="end">
                    <Text as="p" variant="bodySm" tone="subdued">
                      <p style={{fontStyle: 'italic'}}>{insight.PostedDate}</p>
                    </Text>
                  </InlineStack>
                </div>
              </a>
            )
          })}
        </div>
        <div className="insight-more">
          <InlineStack align="center">
            <Link removeUnderline 
              target='_blank'
              url='https://madgictracking.com/index.php/category/all'
            >
              View More
            </Link>
          </InlineStack>
        </div>
      </div>
    </Card>
  )
}

import timezones from "../config/timezone.config";
import { type EVENT_API_TARGET, QUERY_DASHBOARD_DATE } from "../dto";

export const getPreviousTime = (previousOption: QUERY_DASHBOARD_DATE) => {
  let day: number = 0;
  switch(previousOption) {
    case QUERY_DASHBOARD_DATE.TODAY:
      day = 0;
      break;
    case QUERY_DASHBOARD_DATE.YESTERDAY:
      day = 1;
      break;
    case QUERY_DASHBOARD_DATE.LAST_7:
      day = 7;
      break;
    case QUERY_DASHBOARD_DATE.LAST_30:
      day = 30;
      break;
    case QUERY_DASHBOARD_DATE.LAST_60:
      day = 60;
      break;
  }

  const startTime =new Date(new Date(Date.now() - day * 24 * 60 * 60 * 1000).setUTCHours(0,0,0,0));
  const endTime = new Date();
  return {startTime, endTime}
}

export const getTimeZoneNumber = (timeZoneValue: string) => {
  return timezones.filter((timezone) => timezone.value === timeZoneValue)[0].time
}

export const GetDatesBetween = (startDate: Date, endDate: Date) => {
  let dateArray: string[] = [];
  
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  for (let currentTime = startTime; currentTime <= endTime; currentTime += 24 * 60 * 60 * 1000) {
    dateArray = [...dateArray, `${new Date(currentTime).getDate()+1}-${new Date(currentTime).getMonth()+1}-${new Date(currentTime).getFullYear()}`]
  }
  return dateArray
}

export const getIDFromGID = (GID: string) => {
  return GID.split('/').pop()
}


export const getGIDFromID = (ID: string, type: EVENT_API_TARGET) => {
  return `gid://shopify/${type}/${ID}`
}

export function OTGetUrlParameter(sParam: string): string {
  let sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return decodeURIComponent(sParameterName[1]) as any;
    }
  }

  return "";
}

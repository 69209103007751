import { 
  Card,
  SkeletonBodyText,
  Text
} from '@shopify/polaris';
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import type { ChartOptions } from 'chart.js';
import { InfoPixelBufferType, type InfoPixelBuffer } from '../../dto';
import { type SelecDashboardDate } from '../../dto';
import { GetDatesBetween } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: ChartOptions<'line'> = {
  interaction: {
    intersect: true
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      min: 0,
      grid: {
        display: true
      }
    }
  },
  maintainAspectRatio: false,
  elements: {
    point: {
      borderWidth: 0,
      radius: 3,
    },
    line: {
      tension: 0.4,
      borderWidth: 2,
      borderJoinStyle: "round",
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: "start",
      maxHeight: 200,
      fullSize: false,
    },
  },
};

interface PurchaseEventProps {
  infoPixelBuffer: InfoPixelBuffer[],
  selectedDates: SelecDashboardDate,
  loading: boolean
}

export default function PurchaseEvent(props: PurchaseEventProps) {
  const labels = GetDatesBetween(props.selectedDates.start, props.selectedDates.end)

  return (
    <Card padding="0">
      <div className="p-2">
        <Text as="p" variant='headingLg' fontWeight='semibold'>Purchase Events</Text>
        <div className="py-2">
          {!props.loading ?
          <Line 
            options={options}
            height={300} 
            data={{
              labels: labels,
              datasets: [
                {
                  label: 'Total Purchase Event',
                  data: props.infoPixelBuffer.find(buf => buf.Type === InfoPixelBufferType.Purchase)?.Datasets,
                  borderColor: '#59A7FF',
                  backgroundColor: '#59A7FF',
                }
              ],
            }}
          />
          :
          <SkeletonBodyText lines={15} />
          }
        </div>
      </div>
    </Card>
  )
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@shopify/polaris/build/esm/styles.css';

import { Provider as ProviderAppBridge } from "@shopify/app-bridge-react";
import translations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame } from "@shopify/polaris";
import { OTGetUrlParameter } from './utils';
import App from './App';
import * as Sentry from "@sentry/react";

const configApp = {
  apiKey: process.env.REACT_APP_API_KEY || "",
  host: OTGetUrlParameter("host"),
  forceRedirect: false,
};
const textLCP = 'Hello. Today is Friday. I hope you have a great weekend! Hello. Today is Friday. I hope you have a great weekend! Hello. Today is Friday. I hope you have a great weekend! Hello. Today is Friday. I hope you have a great weekend! Hello. Today is Friday. I hope you have a great weekend!';

Sentry.init({
  dsn: "https://b31aa56e8830f0755993467ff918438e@o4506381214154752.ingest.sentry.io/4506427050491904",
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProvider i18n={translations}>
    <ProviderAppBridge config={configApp}>
      <React.StrictMode>
        <Frame>
          <App />
          <p style={{color: "#f1f2f4"}}>{textLCP}</p>
        </Frame>
      </React.StrictMode>
    </ProviderAppBridge>
  </AppProvider>
);


import { 
  Card,
  BlockStack,
  Text,
  InlineStack,
  Link,
  Modal,
  Frame,
  TextField,
  Icon
} from '@shopify/polaris'
import './Feedback.css';
import { useCallback, useState } from 'react'
import type { Dispatch, SetStateAction } from "react";
import { createRating } from '../../api/dashboard';
import GoodIcon from '../../assets/svg/good_icon.svg';
import SadIcon from '../../assets/svg/sad_icon.svg';
import {
  AlertMinor
} from '@shopify/polaris-icons';
import PluginChat from '../../config/pluginChat';

export default function Feedback() {
  const [openFeedbackModal,setOpenFeedbackModal] = useState<boolean>(false)

  return (
    <div className='feedback'>
      <Card padding="0">
        <div className="feedback-btns p-2">
          <div className="py-1">
            <BlockStack align='center' inlineAlign='center'>
              <div className="py-1"></div>
              <Text as="p" variant='headingSm'>We'd love to hear all about your experience</Text>
            </BlockStack>
          </div>
          <InlineStack blockAlign='center' align='center' gap='400'>
            <img src={GoodIcon} alt="goodIcon" />
            <div onClick={() => setOpenFeedbackModal(true)}>
              <img src={SadIcon} alt="sadIcon" />
            </div>
          </InlineStack>
        </div>
        <div className="p-2">
          <div className="mb-1">
            <Text as="strong">Free check your Tiktok Pixel setup</Text>
          </div>
          <div className="mb-1">
            <Text as="p">Are you confused about something? I'm here to clear all of your doubts. If you want, I'll be happy to do a screen-sharing call with you and walk you through all the setup and make sure that you're all set.</Text>
          </div>
          <Text as="p">
            Schedule a meeting with {" "}
            <Link 
              monochrome
              onClick={() => {
                PluginChat.openChat()
              }}
            >
              <Text 
                as="p" 
                fontWeight='semibold'
              >
                Technical Supporter
              </Text>
            </Link> 
          </Text>
        </div>
      </Card>
      <ModalFeedback 
        active={openFeedbackModal}
        setActive={setOpenFeedbackModal}
      />
    </div>
  )
}

function ModalFeedback(props: 
  {
    active: boolean,
    setActive: Dispatch<SetStateAction<boolean>>
  }) 
  {
  const [feedback, setFeedback] = useState<string>("")
  const [isFeedbackSuccess, setFeedbackSuccess] = useState<boolean>(false);
  const [feedBackError, setFeedbackError] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(() => props.setActive(!props.active), [props.active]);

  const submitFeedback = async () => {
    if (!feedback) {
      setFeedbackError(true);
      return;
    }

    const data = await createRating(feedback);
    if (data.IsSuccess) {
      setFeedbackSuccess(true)
    }
  }

  return (
    <div style={{height: '500px'}}>
      <Frame>
        <Modal
          iFrameName='feedback-modal'
          noScroll={true}
          open={props.active}
          onClose={handleChange}
          title="What we could have done better?"
          titleHidden={isFeedbackSuccess}
          primaryAction={!isFeedbackSuccess ? {
            content: 'Send',
            onAction: submitFeedback,
          } : undefined}
          secondaryActions={!isFeedbackSuccess ? [
            {
              content: "I'll leave later",
              onAction: handleChange,
            },
          ] : undefined}
        >
          {!isFeedbackSuccess ?
          <Modal.Section>
            <p className='mb-2'>
              We'll make sure that our team take every feedback into consideration to provide you a better app everytime you visit!
            </p>
            <TextField 
              value={feedback} 
              onChange={(value) => {
                setFeedbackError(false)
                setFeedback(value)
              }} 
              autoComplete="off" 
              label=""
              multiline={5}
            />
            {feedBackError &&
            <div className="mt-1">
              <InlineStack blockAlign="center">
                <Icon source={AlertMinor} tone="critical"/>
              <Text as="p" tone="critical">Feedback is required</Text>
            </InlineStack>
            </div> 
            }
          </Modal.Section>
          : 
          <Modal.Section>
            <div className='feedback-thank'>
              <div className='mb-2'>Your feedback is very important to us.</div>
              <div>
                Our team will look at yours
              </div>
            </div>
          </Modal.Section>
          }
        </Modal>
      </Frame>
    </div>
  );
}

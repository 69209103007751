import type { TimeZone } from "../dto";

const timezones : TimeZone[] = [
  { label: "Default timezone", value: "Default timezone", time: 0 },
  {
    label: "(UTC-11:00) Midway Island",
    value: "(UTC-11:00) Midway Island",
    time: -11,
  },
  { label: "(UTC-11:00) Samoa", value: "(UTC-11:00) Samoa", time: -11 },
  { label: "(UTC-10:00) Hawaii", value: "(UTC-10:00) Hawaii", time: -10 },
  { label: "(UTC-09:00) Alaska", value: "(UTC-09:00) Alaska", time: -9 },
  {
    label: "(UTC-08:00) Pacific Time (US & Canada)",
    value: "(UTC-08:00) Pacific Time (US & Canada)",
    time: -8,
  },
  { label: "(UTC-08:00) Tijuana", value: "(UTC-08:00) Tijuana", time: -8 },
  { label: "(UTC-07:00) Arizona", value: "(UTC-07:00) Arizona", time: -7 },
  { label: "(UTC-07:00) Chihuahua", value: "(UTC-07:00) Chihuahua", time: -7 },
  { label: "(UTC-07:00) La Paz", value: "(UTC-07:00) La Paz", time: -7 },
  { label: "(UTC-07:00) Mazatlan", value: "(UTC-07:00) Mazatlan", time: -7 },
  {
    label: "(UTC-07:00) Mountain Time (US & Canada)",
    value: "(UTC-07:00) Mountain Time (US & Canada)",
    time: -7,
  },
  {
    label: "(UTC-06:00) Central America",
    value: "(UTC-06:00) Central America",
    time: -6,
  },
  {
    label: "(UTC-06:00) Central Time (US & Canada)",
    value: "(UTC-06:00) Central Time (US & Canada)",
    time: -6,
  },
  {
    label: "(UTC-06:00) Guadalajara",
    value: "(UTC-06:00) Guadalajara",
    time: -6,
  },
  {
    label: "(UTC-06:00) Mexico City",
    value: "(UTC-06:00) Mexico City",
    time: -6,
  },
  { label: "(UTC-06:00) Monterrey", value: "(UTC-06:00) Monterrey", time: -6 },
  {
    label: "(UTC-06:00) Saskatchewan",
    value: "(UTC-06:00) Saskatchewan",
    time: -6,
  },
  { label: "(UTC-05:00) Bogota", value: "(UTC-05:00) Bogota", time: -5 },
  {
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    value: "(UTC-05:00) Eastern Time (US & Canada)",
    time: -5,
  },
  {
    label: "(UTC-05:00) Indiana (East)",
    value: "(UTC-05:00) Indiana (East)",
    time: -5,
  },
  { label: "(UTC-05:00) Lima", value: "(UTC-05:00) Lima", time: -5 },
  { label: "(UTC-05:00) Quito", value: "(UTC-05:00) Quito", time: -5 },
  {
    label: "(UTC-04:00) Atlantic Time (Canada)",
    value: "(UTC-04:00) Atlantic Time (Canada)",
    time: -4,
  },
  { label: "(UTC-04:30) Caracas", value: "(UTC-04:30) Caracas", time: -4.5 },
  { label: "(UTC-04:00) La Paz", value: "(UTC-04:00) La Paz", time: -4 },
  { label: "(UTC-04:00) Santiago", value: "(UTC-04:00) Santiago", time: -3.5 },
  {
    label: "(UTC-03:30) Newfoundland",
    value: "(UTC-03:30) Newfoundland",
    time: -3,
  },
  { label: "(UTC-03:00) Brasilia", value: "(UTC-03:00) Brasilia", time: -3 },
  {
    label: "(UTC-03:00) Buenos Aires",
    value: "(UTC-03:00) Buenos Aires",
    time: -3,
  },
  {
    label: "(UTC-03:00) Georgetown",
    value: "(UTC-03:00) Georgetown",
    time: -3,
  },
  { label: "(UTC-03:00) Greenland", value: "(UTC-03:00) Greenland", time: -3 },
  {
    label: "(UTC-02:00) Mid-Atlantic",
    value: "(UTC-02:00) Mid-Atlantic",
    time: -2,
  },
  { label: "(UTC-01:00) Azores", value: "(UTC-01:00) Azores", time: -1 },
  {
    label: "(UTC-01:00) Cape Verde Is.",
    value: "(UTC-01:00) Cape Verde Is.",
    time: -1,
  },
  { label: "(UTC+00:00) Casablanca", value: "(UTC+00:00) Casablanca", time: 0 },
  { label: "(UTC+00:00) Edinburgh", value: "(UTC+00:00) Edinburgh", time: 0 },
  {
    label: "(UTC+00:00) Greenwich Mean Time : Dublin",
    value: "(UTC+00:00) Greenwich Mean Time : Dublin",
    time: 0,
  },
  { label: "(UTC+00:00) Lisbon", value: "(UTC+00:00) Lisbon", time: 0 },
  { label: "(UTC+00:00) London", value: "(UTC+00:00) London", time: 0 },
  { label: "(UTC+00:00) Monrovia", value: "(UTC+00:00) Monrovia", time: 0 },
  { label: "(UTC+00:00) UTC", value: "(UTC+00:00) UTC", time: 0 },
  { label: "(UTC+01:00) Amsterdam", value: "(UTC+01:00) Amsterdam", time: 1 },
  { label: "(UTC+01:00) Belgrade", value: "(UTC+01:00) Belgrade", time: 1 },
  { label: "(UTC+01:00) Berlin", value: "(UTC+01:00) Berlin", time: 1 },
  { label: "(UTC+01:00) Bern", value: "(UTC+01:00) Bern", time: 1 },
  { label: "(UTC+01:00) Bratislava", value: "(UTC+01:00) Bratislava", time: 1 },
  { label: "(UTC+01:00) Brussels", value: "(UTC+01:00) Brussels", time: 1 },
  { label: "(UTC+01:00) Budapest", value: "(UTC+01:00) Budapest", time: 1 },
  { label: "(UTC+01:00) Copenhagen", value: "(UTC+01:00) Copenhagen", time: 1 },
  { label: "(UTC+01:00) Ljubljana", value: "(UTC+01:00) Ljubljana", time: 1 },
  { label: "(UTC+01:00) Madrid", value: "(UTC+01:00) Madrid", time: 1 },
  { label: "(UTC+01:00) Paris", value: "(UTC+01:00) Paris", time: 1 },
  { label: "(UTC+01:00) Prague", value: "(UTC+01:00) Prague", time: 1 },
  { label: "(UTC+01:00) Rome", value: "(UTC+01:00) Rome", time: 1 },
  { label: "(UTC+01:00) Sarajevo", value: "(UTC+01:00) Sarajevo", time: 1 },
  { label: "(UTC+01:00) Skopje", value: "(UTC+01:00) Skopje", time: 1 },
  { label: "(UTC+01:00) Stockholm", value: "(UTC+01:00) Stockholm", time: 1 },
  { label: "(UTC+01:00) Vienna", value: "(UTC+01:00) Vienna", time: 1 },
  { label: "(UTC+01:00) Warsaw", value: "(UTC+01:00) Warsaw", time: 1 },
  {
    label: "(UTC+01:00) West Central Africa",
    value: "(UTC+01:00) West Central Africa",
    time: 1,
  },
  { label: "(UTC+01:00) Zagreb", value: "(UTC+01:00) Zagreb", time: 1 },
  { label: "(UTC+02:00) Athens", value: "(UTC+02:00) Athens", time: 2 },
  { label: "(UTC+02:00) Bucharest", value: "(UTC+02:00) Bucharest", time: 2 },
  { label: "(UTC+02:00) Cairo", value: "(UTC+02:00) Cairo", time: 2 },
  { label: "(UTC+02:00) Harare", value: "(UTC+02:00) Harare", time: 2 },
  { label: "(UTC+02:00) Helsinki", value: "(UTC+02:00) Helsinki", time: 2 },
  { label: "(UTC+02:00) Istanbul", value: "(UTC+02:00) Istanbul", time: 2 },
  { label: "(UTC+02:00) Jerusalem", value: "(UTC+02:00) Jerusalem", time: 2 },
  { label: "(UTC+02:00) Kyiv", value: "(UTC+02:00) Kyiv", time: 2 },
  { label: "(UTC+02:00) Pretoria", value: "(UTC+02:00) Pretoria", time: 2 },
  { label: "(UTC+02:00) Riga", value: "(UTC+02:00) Riga", time: 2 },
  { label: "(UTC+02:00) Sofia", value: "(UTC+02:00) Sofia", time: 2 },
  { label: "(UTC+02:00) Tallinn", value: "(UTC+02:00) Tallinn", time: 2 },
  { label: "(UTC+02:00) Vilnius", value: "(UTC+02:00) Vilnius", time: 2 },
  { label: "(UTC+03:00) Baghdad", value: "(UTC+03:00) Baghdad", time: 3 },
  { label: "(UTC+03:00) Kuwait", value: "(UTC+03:00) Kuwait", time: 3 },
  { label: "(UTC+03:00) Minsk", value: "(UTC+03:00) Minsk", time: 3 },
  { label: "(UTC+03:00) Nairobi", value: "(UTC+03:00) Nairobi", time: 3 },
  { label: "(UTC+03:00) Riyadh", value: "(UTC+03:00) Riyadh", time: 3 },
  { label: "(UTC+03:00) Volgograd", value: "(UTC+03:00) Volgograd", time: 3 },
  { label: "(UTC+03:30) Tehran", value: "(UTC+03:30) Tehran", time: 3.5 },
  { label: "(UTC+04:00) Abu Dhabi", value: "(UTC+04:00) Abu Dhabi", time: 4 },
  { label: "(UTC+04:00) Baku", value: "(UTC+04:00) Baku", time: 4 },
  { label: "(UTC+04:00) Moscow", value: "(UTC+04:00) Moscow", time: 4 },
  { label: "(UTC+04:00) Muscat", value: "(UTC+04:00) Muscat", time: 4 },
  {
    label: "(UTC+04:00) St. Petersburg",
    value: "(UTC+04:00) St. Petersburg",
    time: 4,
  },
  { label: "(UTC+04:00) Tbilisi", value: "(UTC+04:00) Tbilisi", time: 4 },
  { label: "(UTC+04:00) Yerevan", value: "(UTC+04:00) Yerevan", time: 4 },
  { label: "(UTC+04:30) Kabul", value: "(UTC+04:30) Kabul", time: 4.5 },
  { label: "(UTC+05:00) Islamabad", value: "(UTC+05:00) Islamabad", time: 5 },
  { label: "(UTC+05:00) Karachi", value: "(UTC+05:00) Karachi", time: 5 },
  { label: "(UTC+05:00) Tashkent", value: "(UTC+05:00) Tashkent", time: 5 },
  { label: "(UTC+05:30) Chennai", value: "(UTC+05:30) Chennai", time: 5.5 },
  { label: "(UTC+05:30) Kolkata", value: "(UTC+05:30) Kolkata", time: 5.5 },
  { label: "(UTC+05:30) Mumbai", value: "(UTC+05:30) Mumbai", time: 5.5 },
  { label: "(UTC+05:30) New Delhi", value: "(UTC+05:30) New Delhi", time: 5.5 },
  {
    label: "(UTC+05:30) Sri Jayawardenepura",
    value: "(UTC+05:30) Sri Jayawardenepura",
    time: 5.5,
  },
  {
    label: "(UTC+05:45) Kathmandu",
    value: "(UTC+05:45) Kathmandu",
    time: 5.75,
  },
  { label: "(UTC+06:00) Almaty", value: "(UTC+06:00) Almaty", time: 6 },
  { label: "(UTC+06:00) Astana", value: "(UTC+06:00) Astana", time: 6 },
  { label: "(UTC+06:00) Dhaka", value: "(UTC+06:00) Dhaka", time: 6 },
  {
    label: "(UTC+06:00) Ekaterinburg",
    value: "(UTC+06:00) Ekaterinburg",
    time: 6,
  },
  { label: "(UTC+06:30) Rangoon", value: "(UTC+06:30) Rangoon", time: 6.5 },
  { label: "(UTC+07:00) Bangkok", value: "(UTC+07:00) Bangkok", time: 7 },
  { label: "(UTC+07:00) Hanoi", value: "(UTC+07:00) Hanoi", time: 7 },
  { label: "(UTC+07:00) Jakarta", value: "(UTC+07:00) Jakarta", time: 7 },
  {
    label: "(UTC+07:00) Novosibirsk",
    value: "(UTC+07:00) Novosibirsk",
    time: 7,
  },
  { label: "(UTC+08:00) Beijing", value: "(UTC+08:00) Beijing", time: 8 },
  { label: "(UTC+08:00) Chongqing", value: "(UTC+08:00) Chongqing", time: 8 },
  { label: "(UTC+08:00) Hong Kong", value: "(UTC+08:00) Hong Kong", time: 8 },
  {
    label: "(UTC+08:00) Krasnoyarsk",
    value: "(UTC+08:00) Krasnoyarsk",
    time: 8,
  },
  {
    label: "(UTC+08:00) Kuala Lumpur",
    value: "(UTC+08:00) Kuala Lumpur",
    time: 8,
  },
  { label: "(UTC+08:00) Perth", value: "(UTC+08:00) Perth", time: 8 },
  { label: "(UTC+08:00) Singapore", value: "(UTC+08:00) Singapore", time: 8 },
  { label: "(UTC+08:00) Taipei", value: "(UTC+08:00) Taipei", time: 8 },
  {
    label: "(UTC+08:00) Ulaan Bataar",
    value: "(UTC+08:00) Ulaan Bataar",
    time: 8,
  },
  { label: "(UTC+08:00) Urumqi", value: "(UTC+08:00) Urumqi", time: 8 },
  { label: "(UTC+09:00) Irkutsk", value: "(UTC+09:00) Irkutsk", time: 9 },
  { label: "(UTC+09:00) Osaka", value: "(UTC+09:00) Osaka", time: 9 },
  { label: "(UTC+09:00) Sapporo", value: "(UTC+09:00) Sapporo", time: 9 },
  { label: "(UTC+09:00) Seoul", value: "(UTC+09:00) Seoul", time: 9 },
  { label: "(UTC+09:00) Tokyo", value: "(UTC+09:00) Tokyo", time: 9 },
  { label: "(UTC+09:30) Adelaide", value: "(UTC+09:30) Adelaide", time: 9.5 },
  { label: "(UTC+09:30) Darwin", value: "(UTC+09:30) Darwin", time: 9.5 },
  { label: "(UTC+10:00) Brisbane", value: "(UTC+10:00) Brisbane", time: 10 },
  { label: "(UTC+10:00) Canberra", value: "(UTC+10:00) Canberra", time: 10 },
  { label: "(UTC+10:00) Guam", value: "(UTC+10:00) Guam", time: 10 },
  { label: "(UTC+10:00) Hobart", value: "(UTC+10:00) Hobart", time: 10 },
  { label: "(UTC+10:00) Melbourne", value: "(UTC+10:00) Melbourne", time: 10 },
  {
    label: "(UTC+10:00) Port Moresby",
    value: "(UTC+10:00) Port Moresby",
    time: 10,
  },
  { label: "(UTC+10:00) Sydney", value: "(UTC+10:00) Sydney", time: 10 },
  { label: "(UTC+10:00) Yakutsk", value: "(UTC+10:00) Yakutsk", time: 10 },
  {
    label: "(UTC+11:00) Vladivostok",
    value: "(UTC+11:00) Vladivostok",
    time: 11,
  },
  { label: "(UTC+12:00) Auckland", value: "(UTC+12:00) Auckland", time: 12 },
  { label: "(UTC+12:00) Fiji", value: "(UTC+12:00) Fiji", time: 12 },
  {
    label: "(UTC+12:00) International Date Line West",
    value: "(UTC+12:00) International Date Line West",
    time: 12,
  },
  { label: "(UTC+12:00) Kamchatka", value: "(UTC+12:00) Kamchatka", time: 12 },
  { label: "(UTC+12:00) Magadan", value: "(UTC+12:00) Magadan", time: 12 },
  {
    label: "(UTC+12:00) Marshall Is.",
    value: "(UTC+12:00) Marshall Is.",
    time: 12,
  },
  {
    label: "(UTC+12:00) New Caledonia",
    value: "(UTC+12:00) New Caledonia",
    time: 12,
  },
  {
    label: "(UTC+12:00) Solomon Is.",
    value: "(UTC+12:00) Solomon Is.",
    time: 12,
  },
  {
    label: "(UTC+12:00) Wellington",
    value: "(UTC+12:00) Wellington",
    time: 12,
  },
  {
    label: "(UTC+13:00) Nuku'alofa",
    value: "(UTC+13:00) Nuku'alofa",
    time: 13,
  },
];

export default timezones;

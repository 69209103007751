import './OverviewData.css';
import { useState, useCallback,type Dispatch,type SetStateAction } from 'react'
import { 
  Card,
  Text,
  Popover,
  Select,
  Button,
  InlineGrid,
  DatePicker,
  InlineStack,
  Icon,
  SkeletonDisplayText,
} 
from "@shopify/polaris"
import { 
  type InfoPixelBuffer, 
  QUERY_DASHBOARD_DATE,
  QUERY_DASHBOARD_DATE_LABEL,
  PixelDashboard
} from '../../dto';
import {
  CalendarMajor
} from '@shopify/polaris-icons';
import moment from 'moment';
import { type SelecDashboardDate } from '../../dto';
import PixelOverview from '../../pages/dashboard/pixel-overview';

interface OverviewDataProps {
  infoPixelBuffer: InfoPixelBuffer[],
  selectedDates: SelecDashboardDate,
  setSelectedDates: Dispatch<SetStateAction<SelecDashboardDate>>, 
  selected: QUERY_DASHBOARD_DATE,
  setSelected: Dispatch<SetStateAction<QUERY_DASHBOARD_DATE>>,
  handleSelectCustomDate: () => void,
  resetCustomDate: () => void,
  pixelList: PixelDashboard[],
  selectedPixel: PixelDashboard | null,
  setSelectedPixel: Dispatch<SetStateAction<PixelDashboard | null>>,
  loading: boolean
}

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();

export default function OverviewData({
  infoPixelBuffer,
  selectedDates,
  setSelectedDates,
  selected,
  setSelected,
  handleSelectCustomDate,
  resetCustomDate,
  pixelList,
  selectedPixel,
  setSelectedPixel,
  loading
}: OverviewDataProps
) {
  const [popoverActive, setPopoverActive] = useState(false);
  const [{month, year}, setDate] = useState({month: currentMonth, year: currentYear});

  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({month, year}),
    [],
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const renderDateType = () => {
    if (selected === QUERY_DASHBOARD_DATE.CUSTOM) {
      return `${moment(selectedDates.start).format('DD/MM/YYYY')}-${moment(selectedDates.end).format('DD/MM/YYYY')}`
    } else {
      return QUERY_DASHBOARD_DATE_LABEL[selected]
    }
  }

  const activator = (
    <div onClick={togglePopoverActive} style={{cursor: 'pointer'}}>
      <InlineStack align='center' blockAlign='center' gap="200">
        <Icon
          source={CalendarMajor}
          tone="textPrimary"
        />
        <Text as='p' variant='headingSm'>{renderDateType()}</Text>
      </InlineStack>
    </div>
  );

  return (
    <Card padding="0">
      <div className="overview">
        <div className="overview-data-container p-2">
          <div className='over-view-data p-3'>
            <Popover
              active={popoverActive}
              activator={activator}
              autofocusTarget="first-node"
              onClose={togglePopoverActive}
              fluidContent={true}
            >
              <div className='p-4' style={{maxWidth: '720px'}}>
                <Select
                  label="Date range"
                  options={[
                    {label: QUERY_DASHBOARD_DATE_LABEL.today, value: QUERY_DASHBOARD_DATE.TODAY},
                    {label: QUERY_DASHBOARD_DATE_LABEL.yesterday, value: QUERY_DASHBOARD_DATE.YESTERDAY},
                    {label: QUERY_DASHBOARD_DATE_LABEL.last7, value: QUERY_DASHBOARD_DATE.LAST_7},
                    {label: QUERY_DASHBOARD_DATE_LABEL.last30, value: QUERY_DASHBOARD_DATE.LAST_30},
                    {label: QUERY_DASHBOARD_DATE_LABEL.last60, value: QUERY_DASHBOARD_DATE.LAST_60},
                    {label: QUERY_DASHBOARD_DATE_LABEL.custom, value: QUERY_DASHBOARD_DATE.CUSTOM},
                  ]}
                  onChange={(value) => setSelected(value as QUERY_DASHBOARD_DATE)}
                  value={selected}
                />
                {selected === QUERY_DASHBOARD_DATE.CUSTOM && 
                <div className="mt-2">
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={setSelectedDates}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    multiMonth
                    allowRange
                  />
                </div>
                }
                <div className="mt-2">
                  <InlineGrid gap="200" columns={2}>
                    <Button 
                      size='large' 
                      fullWidth 
                      variant="primary"
                      onClick={() => {
                        handleSelectCustomDate();
                        togglePopoverActive()
                      }}
                    >
                      Selected DateTime
                    </Button>
                    <Button 
                      size='large' 
                      fullWidth
                      onClick={() => resetCustomDate()} 
                    >
                      Reset DateTime
                      </Button>
                  </InlineGrid>   
                </div>
              </div>
            </Popover>
          </div>
          <div className='over-view-data p-3'>
            <Text as='p' variant='headingSm'>Tiktok Pixel</Text>
            <PixelOverview 
              pixelList={pixelList}
              selectedPixel={selectedPixel}
              setSelectedPixel={setSelectedPixel}
            />
          </div>
          {(infoPixelBuffer).map((info) => {
            return (
              <div className='over-view-data p-3' key={info.Type}>
                <Text as='p' variant='headingSm'>{info.Title}</Text>
                {!loading ?
                <Text as="p" variant='headingSm'>{info.TotalEvents}</Text>
                :
                <SkeletonDisplayText  size="small" />
                }
              </div>
            )
          })}
        </div>
        <div className="overview-des">
          <p className='ml-2'>All events are tracked by Conversion API only</p>
        </div>
      </div>
    </Card>
  )
}

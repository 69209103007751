export * from './dashboard';
export interface ToturialDto {
  index: number;
  title: string;
  des: string;
  thumbnailUrl: string;
  link: string;
  vidLength: number;
}

export enum PROGRESS {
  _0 = 0,
  _25 = 25,
  _50 = 50,
  _75 = 75,
  _100 = 100,
}

export enum QUERY_DASHBOARD_DATE {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7 = 'last7',
  LAST_30 = 'last30',
  LAST_60 = 'last60',
  CUSTOM = 'custom',
}

interface QueryDashboardDateLabel {
  today: string;
  yesterday: string
  last7: string
  last30: string
  last60: string
  custom: string
}

export const QUERY_DASHBOARD_DATE_LABEL: QueryDashboardDateLabel = {
  [QUERY_DASHBOARD_DATE.TODAY]: 'Today',
  [QUERY_DASHBOARD_DATE.YESTERDAY]: 'Yesterday',
  [QUERY_DASHBOARD_DATE.LAST_7]: 'Last 7 day',
  [QUERY_DASHBOARD_DATE.LAST_30]: 'Last 30 day',
  [QUERY_DASHBOARD_DATE.LAST_60]: 'Last 60 day',
  [QUERY_DASHBOARD_DATE.CUSTOM]: 'Custom'
}

export enum EVENT_API_TARGET {
  ENTIRE_STORE = 'All',
  COLLECTIONS = 'Collection',
  PRODUCTS = 'Product',
}

export enum API_TYPE {
  BROWSER = 'Browser',
  EVENTS_API = 'Events API',
}

export interface ProductDto  {
  id: string
  title: string;
}

export interface CollectionDto  {
  id: string
  title: string;
}

export enum CreateStatus {
  NewCreate = 'NewCreate',
  AlreadyCreate = 'AlreadyCreate',
  Edit = 'Edit',
}

export interface SelecDashboardDate {
  start: Date;
  end: Date
}
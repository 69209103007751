import './carousel.css'
import { useState, useEffect } from "react";
import { Button } from "@shopify/polaris";
// import { useViewport } from '~/plugins/customHook/useViewPort';
import type { Recommend } from '../recommendApp/RecommendApp';


const Carousel = (
  { 
    addons,
    slide, 
    numberRandom, 
    handleCheckItOut 
  }:
  {
  addons: Recommend[],
  slide: string,
  numberRandom: number,
  handleCheckItOut: (addon: Recommend) => void
  }
  ) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [activeIndex, setActiveIndex] = useState(0);
  const [transform, setTransform] = useState(0);
  const [widthItem, setWidth] = useState(500);
  const [transformSkip, setTransformSkip] = useState(520);

  useEffect(() => {
    if (slide === "left") {
      if (transform + transformSkip <= 0) {
        setActiveIndex(activeIndex - 1);
        setTransform(transform + transformSkip);
      }
    }
    if (slide === "right") {
      setActiveIndex(activeIndex + 1);
      setTransform(transform - transformSkip);
    }
  }, [slide, numberRandom]);

  return (
    <div className="carousel">
      <ul style={{ transform: `translateX(${transform}px)` }}>
        {addons.map((addon, index) => (
          <li
            key={index}
            // className={index === activeIndex ? "active" : undefined}
          >
            <div className="carousel-item" style={{ width: `${widthItem}px` }}>
              <div className="carousel-item_header">
                <div className="carousel-item_header__image">
                  <img width={160} height={71} src={addon.ImageUrl} alt="recommendImg"/>
                </div>
                <div className="carousel-item_header__button">
                  {addon.Used ? (
                    <Button disabled> Added</Button>
                  ) : (
                    <Button
                      id="primary"
                      onClick={() => handleCheckItOut(addon)}
                    >
                      {" "}
                      Check it out
                    </Button>
                  )}
                </div>
              </div>
              <div className="carousel-item_title">{addon.Name}</div>
              <div className="carousel-item_des">{addon.Title}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Carousel;

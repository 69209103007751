import {
    BlockStack,
    Card,
    Grid,
    Page,
    Text,
    Button,
    ButtonGroup,
    TextField,
    InlineStack,
    Link,
    Label,
    FormLayout,
    Select,
    Checkbox,
    Icon
  } from "@shopify/polaris";
  import { useState, useCallback } from "react";
  import type { Dispatch, SetStateAction } from "react";
  import { useNavigate } from "react-router-dom";
  import { ResourcePicker } from '@shopify/app-bridge-react';
  import Progress from "../../components/progress/Progress";
  import Toturial from "../../components/toturial/Toturial";
  import { 
    API_TYPE, 
    EVENT_API_TARGET, 
    PROGRESS, 
    type ToturialDto, 
    type ProductDto, 
    type CollectionDto, CreateStatus 
  } from "../../dto";
  import type { SelectPayload } from "@shopify/app-bridge-react/components/ResourcePicker/ResourcePicker";
  import {
    CancelMajor,
    AlertMinor
  } from '@shopify/polaris-icons';

  const apiTargetOptions = [
    {label: 'Entire Store', value: EVENT_API_TARGET.ENTIRE_STORE},
    {label: 'Collections', value: EVENT_API_TARGET.COLLECTIONS},
    {label: 'Products', value: EVENT_API_TARGET.PRODUCTS},
  ];

  const apiTypeLabelValue = [
    {label: API_TYPE.BROWSER, value: API_TYPE.BROWSER},
    {label: API_TYPE.EVENTS_API, value: API_TYPE.EVENTS_API},
  ];

  const step2ToturialForBrowser : ToturialDto[] = [
    {
    index: 1,
    title: 'How To Find The Tiktok Pixel ID?',
    des: 'Let’s discover how to find TikTok ID with our complete video instructions!',
    thumbnailUrl: 'https://i3.ytimg.com/vi/dq-hqrFze9M/maxresdefault.jpg',
    link: 'https://www.youtube.com/watch?v=dq-hqrFze9M',
    vidLength: 80,
    }
  ]

  
  const step2ToturialForEvents : ToturialDto[] = [
    {
    index: 1,
    title: 'How To Find Tiktok Pixel Access Token?',
    des: 'Let’s discover how to find TikTok Pixel Access Token',
    thumbnailUrl: 'https://i3.ytimg.com/vi/BTHRcpdO714/maxresdefault.jpg',
    link: 'https://www.youtube.com/watch?v=BTHRcpdO714',
    vidLength: 50,
    },
    {
    index: 2,
    title: 'Test TikTok Pixel Events Video Walkthrough',
    des: "This video will show you how to use the Pixel's Diagnostic Suite to test your pixel's setup and events directly from TikTok Ads Manager.",
    thumbnailUrl: "https://i3.ytimg.com/vi/8dFsgeFMQc0/maxresdefault.jpg",
    link: 'https://www.youtube.com/watch?v=8dFsgeFMQc0',
    vidLength: 81,
    }
  ]

interface Step2Props {
  createStatus: CreateStatus;
  progress: number;
  setProgress: Dispatch<SetStateAction<PROGRESS>>
  pixelTitle: string;
  setPixelTitle: Dispatch<SetStateAction<string>>
  pixelID: string;
  setPixelID: Dispatch<SetStateAction<string>>;
  selectedCollect: CollectionDto[];
  setSelectedCollect: Dispatch<SetStateAction<CollectionDto[]>>;
  selectedProduct: ProductDto[];
  setSelectedtProduct: Dispatch<SetStateAction<ProductDto[]>>;
  apiType: API_TYPE;
  setApiType: Dispatch<SetStateAction<API_TYPE>>;
  selectedTarget: EVENT_API_TARGET;
  setSelectedTarget: Dispatch<SetStateAction<EVENT_API_TARGET>>;
  testCode: string;
  setTestCode: Dispatch<SetStateAction<string>>;
  tokenAccess: string;
  setTokenAccess: Dispatch<SetStateAction<string>>;
  isEnableStatus: boolean;
  setEnableStatus: Dispatch<SetStateAction<boolean>>;
  handleEditPixel?: () => void;
  handleCreatePixel?: () => void;
}

export default function Step2(props: Step2Props) {
  const navigate = useNavigate()

  const [openProductPicker,setOpenProductPicker] = useState<boolean>(false);
  const [openCollectionPicker,setOpenCollectionPicker] = useState<boolean>(false);
  const [errorPixelTitle, setErrorPixelTitle] = useState<boolean>(false);
  const [errorPixelTitleMsg, setErrorPixelTitleMsg] = useState<string>("");
  const [errorPixelID, setErrorPixelID] = useState<boolean>(false);
  const [errorPixelIDMsg, setErrorPixelIDMsg] = useState<string>("");
  const [errorTokenAccess, setErrorTokenAccess] = useState<boolean>(false);
  const [errorTokenAccessMsg, setErrorTokenAccessMsg] = useState<string>("");
  const [errorTestCode, setErrorTestCode] = useState<boolean>(false);
  const [errorTestCodeMsg, setErrorTestCodeMsg] = useState<string>("");
  const handleSelectChange = useCallback(
    (value: EVENT_API_TARGET) => props.setSelectedTarget(value),
     // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderAPISelect = (selectValue: string) => {
    return apiTypeLabelValue.map((type, index) => {
      const isSelected = selectValue === type.value;
      return  (
        <div 
          key={type.value}
          onClick={() => props.setApiType(type.value)}
        >
          <div 
            className={`select-api-btn ${isSelected ? 'selected' : ''} ${index === 0 ? 'select-api-btn-left' : 'select-api-btn-right'}`}
          >
            <p
              style={{
                color: isSelected ? '#fff' : '#000',
              }}
            >
            {type.label}
            </p>
          </div>
        </div>
    )
    })
  }

  const renderSelecTargetBtn = () => {
    if (props.selectedTarget ===  EVENT_API_TARGET.ENTIRE_STORE) {
      return null
    }
    if (props.selectedTarget ===  EVENT_API_TARGET.COLLECTIONS) {
      return (
        <Button 
          size="large" 
          onClick={() => setOpenCollectionPicker(true)}
        >
          Select Collections
        </Button>
        )
    }
    if (props.selectedTarget ===  EVENT_API_TARGET.PRODUCTS) {
      return (
        <Button 
          size="large" 
          onClick={() => setOpenProductPicker(true)}
        >
          Select Products
        </Button>
        )
    }
  }

  const handleSelectProduct = (selectPayload: SelectPayload) => {
    setOpenProductPicker(false)
    const list = selectPayload.selection.map((product) => {
      return {
        id: product.id,
        title: product.title
      }
    })
    props.setSelectedtProduct(list);
  }

  const handleRemoveProduct = (productId: string) => {
    const list = props.selectedProduct.filter((product) => {
      return product.id !== productId
    })
    props.setSelectedtProduct(list)
  }

  const handleSelectCollection = (selectPayload: SelectPayload) => {
    setOpenCollectionPicker(false)
    const list = selectPayload.selection.map((collection) => {
      return {
        id: collection.id,
        title: collection.title
      }
    })
    props.setSelectedCollect(list);
  }

  const handleRemoveCollection = (collectionId: string) => {
    const list = props.selectedCollect.filter((collection) => {
      return collection.id !== collectionId
    })
    props.setSelectedCollect(list)
  }

  const checkValidStep2 = () => {
    let step2Valid: boolean = true;

    if (!props.pixelTitle) {
      setErrorPixelTitle(true)
      setErrorPixelTitleMsg('Title is required.')
      step2Valid = false
    }

    if (props.pixelTitle.length > 250) {
      setErrorPixelTitle(true)
      setErrorPixelTitleMsg('Title is 250 characters max.')
      step2Valid = false
    }

    if (!props.pixelID) {
      setErrorPixelID(true)
      setErrorPixelIDMsg('Tiktok Pixel ID is required.')
      step2Valid = false
    }

    if (props.pixelID.length > 50) {
      setErrorPixelID(true)
      setErrorPixelIDMsg('Tiktok Pixel ID is 50 characters max.')
      step2Valid = false
    }

    if (props.apiType === API_TYPE.EVENTS_API) {
      if (!props.tokenAccess) {
        setErrorTokenAccess(true)
        setErrorTokenAccessMsg('Tiktok Pixel Access Token is required.')
        step2Valid = false
      }
      if (props.tokenAccess.length > 50) {
        setErrorTokenAccess(true)
        setErrorTokenAccessMsg('Tiktok Pixel Access Token is 50 characters max.')
        step2Valid = false
      }
    }

    if (props.apiType === API_TYPE.EVENTS_API && props.testCode) {
      if (props.testCode.length > 10 || !props.testCode.startsWith('TEST')) {
        setErrorTestCode(true)
        setErrorTestCodeMsg('Test Code is 10 characters max and must start with "TEST"')
        step2Valid = false
      }
    }

    return step2Valid 
  }

  const submitStep2 = () => {
    const isStep2Valid = checkValidStep2();
    if (!isStep2Valid) {
      return;
    }
    props.setProgress(PROGRESS._50)
  }

  return (
    <Page fullWidth>
      {props.createStatus === CreateStatus.Edit &&
      <div className="mb-2">
        <InlineStack blockAlign="center" align="end">
          <Button onClick={() => navigate('/pixel/manage-pixel')}>Back to Manage pixel</Button>
        </InlineStack>
      </div>
      }
      <Grid>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 2, lg: 4, xl: 4}}>
          {props.createStatus === CreateStatus.NewCreate && 
            <Progress progress={props.progress}  onClick={() => {
              const isStep2Valid = checkValidStep2();
              if (!isStep2Valid) {
                return;
              }
              props.handleCreatePixel && props.handleCreatePixel()
            }}/>
          }
          <Toturial 
            toturials={props.apiType === API_TYPE.BROWSER ? step2ToturialForBrowser : step2ToturialForEvents}
            title="More tips on getting started"
            subTitle="This is series introduce Tiktok Events API"
          />
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 4, lg: 8, xl: 8}}>
          <Card >
            {props.createStatus === CreateStatus.NewCreate &&
              <Text as="h2" variant="headingLg">Step2: Install Tiktok Pixel</Text>
            }
            {props.createStatus === CreateStatus.AlreadyCreate &&
              <Text as="h2" variant="headingLg">Create New Tiktok Pixel</Text>
            }
            {props.createStatus === CreateStatus.Edit &&
              <Text as="h2" variant="headingLg">Edit Tiktok Pixel</Text>
            }
            <BlockStack gap="500">
              <FormLayout>
                <div className="my-2">
                  <Label id="Tiktok-pixel">
                    <Text as="p" fontWeight="medium">Tiktok Pixel Title<Text tone="critical" as="span">(*)</Text></Text>
                  </Label>
                  <TextField 
                    value={props.pixelTitle} 
                    onChange={(value) => {
                      setErrorPixelTitle(false)
                      props.setPixelTitle(value)
                    }} 
                    autoComplete="on" 
                    label=""
                  />
                   {errorPixelTitle && 
                   <InlineStack blockAlign="center">
                      <Icon source={AlertMinor} tone="critical"/>
                      <Text as="p" tone="critical">{errorPixelTitleMsg}</Text>
                    </InlineStack>
                   }
                  <Text variant="bodySm" as="p" tone="subdued">
                    <span style={{fontStyle: 'italic'}}>It helps you to do easily management Tiktok pixel</span>
                  </Text>
                </div>
                <div className="my-2">
                  <InlineStack align="space-between">
                    <Label id="tiktok-pixel-id">
                      <Text as="p" fontWeight="medium">Tiktok Pixel ID<Text tone="critical" as="span">(*)</Text></Text>
                    </Label>
                    <Link
                      url="https://madgictracking.com/index.php/2023/11/20/find-the-tiktok-pixel-id"
                      target="_blank"
                      removeUnderline
                    >
                      Where can I get it?
                    </Link>
                  </InlineStack>
                  <TextField
                    label=""
                    value={props.pixelID}
                    onChange={(value) => {
                      setErrorPixelID(false)
                      props.setPixelID(value)
                    }} 
                    autoComplete="off"
                  />
                  {errorPixelID &&
                  <InlineStack blockAlign="center">
                    <Icon source={AlertMinor} tone="critical"/>
                    <Text as="p" tone="critical">{errorPixelIDMsg}</Text>
                  </InlineStack> 
                  }
                </div>
                <BlockStack>
                  <InlineStack>
                    {renderAPISelect(props.apiType)}
                  </InlineStack>
                </BlockStack>
                {props.apiType === API_TYPE.BROWSER 
                ?
                <div className="my-2">
                  <Text as="p" breakWord={true}>
                    Send events and parameters through web browsers using Tiktok pixel. 
                  </Text>
                </div>
                :
                <BlockStack>
                  <div className="my-2">
                    <Text as="p" breakWord={true}>
                      Send events and parameters directly from our server to Tiktok using events API.  This means the data can’t be blocked by ad blockers, IOS 14+
                    </Text>
                  </div>
                  <div className="my-1">
                    <InlineStack align="space-between">
                      <Label id="Tiktok-pixel">
                        <Text as="p" fontWeight="medium">Tiktok Pixel Access Token<Text tone="critical" as="span">(*)</Text></Text>
                      </Label>
                      <Link
                        url="https://madgictracking.com/index.php/2023/11/20/tiktok-pixel-access-token"
                        target="_blank"
                        removeUnderline
                      >
                        Where can I get it?
                      </Link>
                    </InlineStack>
                    <TextField
                      value={props.tokenAccess} 
                      onChange={(value) => {
                        setErrorTokenAccess(false)
                        props.setTokenAccess(value)
                      }}  
                      autoComplete="off" 
                      label=""
                    />
                    {errorTokenAccess && 
                    <InlineStack blockAlign="center">
                      <Icon source={AlertMinor} tone="critical"/>
                      <Text as="p" tone="critical">{errorTokenAccessMsg}</Text>
                    </InlineStack>
                    }
                  </div>
                  <div className="my-1">
                    <Label id="tiktok-pixel-id">
                      <Text as="p" fontWeight="medium">Test Code Event</Text>
                    </Label>
                    <TextField
                      label=""
                      value={props.testCode}
                      onChange={(value) => {
                        setErrorTestCode(false)
                        props.setTestCode(value)
                      }}
                      autoComplete="off"
                    />
                    {errorTestCode && 
                    <InlineStack blockAlign="center">
                      <Icon source={AlertMinor} tone="critical"/>
                      <Text as="p" tone="critical">{errorTestCodeMsg}</Text>
                    </InlineStack>
                    }
                    <Text variant="bodySm" as="p" tone="subdued">You can check if all your events are received correctly by Test Event Code. 
                    <Text tone="critical" as="span">{" "}When you are ready, you can remove the Test Event Code.</Text>
                    </Text>
                  </div>
                </BlockStack>
                }
                <BlockStack>
                  <Label id="target">
                    <Text as="p" fontWeight="medium">Target</Text>
                  </Label>
                  <Select
                    label=""
                    options={apiTargetOptions}
                    onChange={handleSelectChange}
                    value={props.selectedTarget} 
                  />
                </BlockStack>
                {props.selectedTarget === EVENT_API_TARGET.PRODUCTS &&
                <BlockStack>
                  <div className="target-selected mt-1">
                    {props.selectedProduct.map((product) => {
                      return (
                      <div className="target-selected-item" key={product.id}>
                        {product.title}
                        <div
                        onClick={() => handleRemoveProduct(product.id)}
                        >
                          <Icon
                            source={CancelMajor}
                            tone="base"
                          />
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </BlockStack>
                }
                {props.selectedTarget === EVENT_API_TARGET.COLLECTIONS &&
                <BlockStack>
                  <div className="target-selected my-1">
                    {props.selectedCollect.map((collection) => {
                      return (
                      <div className="target-selected-item" key={collection.id}>
                        {collection.title}
                        <div
                        onClick={() => handleRemoveCollection(collection.id)}
                        >
                          <Icon
                            source={CancelMajor}
                            tone="base"
                          />
                        </div>
                      </div>
                      )
                    })}
                  </div>
                </BlockStack>
                }
                <BlockStack >
                  <div className="mt-2">
                    <ButtonGroup>
                      {renderSelecTargetBtn()}
                    </ButtonGroup>
                  </div>
                </BlockStack>
                  <ResourcePicker 
                    resourceType="Product"
                    showVariants={false}
                    open={openProductPicker}
                    onSelection={handleSelectProduct}
                    onCancel={() => setOpenProductPicker(false)}
                    initialSelectionIds={props.selectedProduct.map((product) => {
                      return {
                        id: product.id
                      }
                    })}
                  />
                  <ResourcePicker 
                    resourceType="Collection"
                    open={openCollectionPicker}
                    onSelection={handleSelectCollection}
                    onCancel={() => setOpenCollectionPicker(false)}
                    initialSelectionIds={props.selectedCollect.map((collection) => {
                      return {
                        id: collection.id
                      }
                    })}
                  />
                <InlineStack blockAlign="center">
                  <Checkbox label="Enabled" id="checkbox" name="checkbox" checked={props.isEnableStatus} onChange={() => props.setEnableStatus(!props.isEnableStatus)} />
                </InlineStack>
              </FormLayout>
            </BlockStack>
            <div className="py-3">
              <BlockStack align="center" inlineAlign="end">
                {props.createStatus === CreateStatus.NewCreate &&
                <ButtonGroup>
                  <Button onClick={() => props.setProgress(PROGRESS._0)}>Back</Button>
                  <Button variant="primary" onClick={() => submitStep2()}>Next Step</Button>
                </ButtonGroup>
                }
                {props.createStatus === CreateStatus.Edit &&
                  <ButtonGroup>
                  <Button variant="primary" onClick={() => {
                    const isStep2Valid = checkValidStep2();
                    if (!isStep2Valid) {
                      return;
                    }
                    props.handleEditPixel && props.handleEditPixel()}
                    }
                  >
                    Save
                  </Button>
                </ButtonGroup>
                }
                {props.createStatus === CreateStatus.AlreadyCreate &&
                  <ButtonGroup>
                  <Button variant="primary" onClick={() => {
                    const isStep2Valid = checkValidStep2();
                    if (!isStep2Valid) {
                      return;
                    }
                    props.handleCreatePixel && props.handleCreatePixel()
                  }}
                  >
                    Create
                  </Button>
                </ButtonGroup>
                }
              </BlockStack>
            </div>
          </Card>
        </Grid.Cell>
      </Grid>
    </Page>
  )
}
  
  
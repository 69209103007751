import './create-pixel.css';
import { useEffect, useState } from 'react';
import type { Dispatch, ReactElement, SetStateAction } from "react";
import {
  Card,
  Frame,
  Grid,
  Page,
  SkeletonBodyText, 
  SkeletonPage,
  Toast
} from "@shopify/polaris";
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/navigation/Navigation';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

import { API_TYPE,
  type CollectionDto,
  type ProductDto,  
  PROGRESS,
  EVENT_API_TARGET,
  CreateStatus
} from '../../dto';
import { CreatePixelRequest, GetPixelList } from '../../api/manage-pixel';
import { getIDFromGID } from '../../utils';

export interface StepProps {
  progress: number;
  setProgress: Dispatch<SetStateAction<PROGRESS>>
}

export default function CreatePixel() {
  const navigate = useNavigate()

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [progress, setProgress] = useState<PROGRESS>(PROGRESS._0)
  const [pixelTitle, setPixelTitle] = useState<string>("");
  const [pixelID, setPixelID] = useState<string>("");
  const [selectedCollect, setSelectedCollect] = useState<CollectionDto[]>([]);
  const [selectedProduct, setSelectedtProduct] = useState<ProductDto[]>([]);
  const [apiType,setApiType] = useState<API_TYPE>(API_TYPE.EVENTS_API);
  const [selectedTarget, setSelectedTarget] = useState<EVENT_API_TARGET>(EVENT_API_TARGET.ENTIRE_STORE);
  const [isEnableStatus, setEnableStatus] = useState<boolean>(true);
  const [testCode, setTestCode] = useState<string>("")
  const [tokenAccess, setTokenAccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [alreadyHavePixel, setAlreadyHavePixel] = useState<boolean>(true);
  const [alert, setAlert] = useState<ReactElement | null>(null)

  useEffect(() => {
    getPixelList()
  }, []);

  const getPixelList = async () => {
    setLoading(true);
    const data = await GetPixelList();
    if (data && data.length > 0) {
      setAlreadyHavePixel(true)
    } else {
      setAlreadyHavePixel(false)
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const resetState = () => {
    setPixelTitle("");
    setPixelID("");
    setSelectedCollect([]);
    setSelectedtProduct([]);
    setApiType(API_TYPE.EVENTS_API);
    setSelectedTarget(EVENT_API_TARGET.ENTIRE_STORE);
    setEnableStatus(true);
    setTestCode("");
    setTokenAccess("")
  }

  const submitCreatePixel = async () => {
    const payload = {
      FacebookPixel: pixelID,
      ID: 0,
      IsEnableConversationAPI: apiType === API_TYPE.EVENTS_API ? true : false,
      IsFirstCreate: false,
      ListCollectionName: selectedCollect.map(collection => collection.title).join('|||'),
      ListProductName: selectedProduct.map(product => product.title).join('|||'),
      LstCollect: selectedCollect.map(collection => getIDFromGID(collection.id) ).join(','),
      LstProduct: selectedProduct.map(product => getIDFromGID(product.id) ).join(','),
      Shop: String(localStorage.getItem('shop')),
      ShopID: Number(localStorage.getItem('shopID')),
      Status: isEnableStatus,
      Target: selectedTarget,
      TestCode: testCode,
      Title: pixelTitle,
      TokenAccess: tokenAccess
    }
    const response = await CreatePixelRequest(payload);
    if (alreadyHavePixel) {
      if (response.isSuccess) {
        setAlert(
          <Frame>
            <Toast
              content={`Success created pixel ${pixelTitle}`}
              onDismiss={() => setAlert(null)}
            />
          </Frame>
        )
        setTimeout(() => {
          navigate('/pixel/manage-pixel')
        }, 1000)
      } else {
        setAlert(
          <Frame>
            <Toast
              error
              content={response.Messenger || 'Something wrong, please try again'}
              onDismiss={() => setAlert(null)}
            />
          </Frame>
        )
      }
    } else {
      setAlert(
        <Frame>
          <Toast
            content={`${pixelTitle} was created!`}
            onDismiss={() => setAlert(null)}
          />
        </Frame>
      )
    }
  }

  const renderContent = () => {
    if (!loading && alreadyHavePixel) {
      return (
      <Step2
        createStatus={CreateStatus.AlreadyCreate} 
        progress={progress}
        setProgress={setProgress}
        pixelTitle={pixelTitle}
        setPixelTitle={setPixelTitle}
        pixelID={pixelID}
        setPixelID={setPixelID}
        selectedCollect={selectedCollect}
        setSelectedCollect={setSelectedCollect}
        selectedProduct={selectedProduct}
        setSelectedtProduct={setSelectedtProduct}
        apiType={apiType}
        setApiType={setApiType}
        selectedTarget={selectedTarget}
        setSelectedTarget={setSelectedTarget}
        testCode={testCode}
        setTestCode={setTestCode}
        tokenAccess={tokenAccess}
        setTokenAccess={setTokenAccess}
        isEnableStatus={isEnableStatus}
        setEnableStatus={setEnableStatus}
        handleCreatePixel={submitCreatePixel}
      />)
    } else {
      return (
      <>
      {progress === PROGRESS._0 && 
      <Step1 
        progress={progress}
        setProgress={setProgress}
      />}
      {progress === PROGRESS._25 && 
        <Step2
          createStatus={CreateStatus.NewCreate} 
          progress={progress}
          setProgress={setProgress}
          pixelTitle={pixelTitle}
          setPixelTitle={setPixelTitle}
          pixelID={pixelID}
          setPixelID={setPixelID}
          selectedCollect={selectedCollect}
          setSelectedCollect={setSelectedCollect}
          selectedProduct={selectedProduct}
          setSelectedtProduct={setSelectedtProduct}
          apiType={apiType}
          setApiType={setApiType}
          selectedTarget={selectedTarget}
          setSelectedTarget={setSelectedTarget}
          testCode={testCode}
          setTestCode={setTestCode}
          tokenAccess={tokenAccess}
          setTokenAccess={setTokenAccess}
          isEnableStatus={isEnableStatus}
          setEnableStatus={setEnableStatus}
        />}
      {progress === PROGRESS._50 && 
      <Step3 
        progress={progress} 
        setProgress={setProgress}
        submitCreatePixel={submitCreatePixel}
      />}
      {progress === PROGRESS._100 &&
      <Step4 
        progress={progress} 
        setProgress={setProgress}
        setAlreadyHavePixel={setAlreadyHavePixel}
        resetState={resetState}
      />}
      </>
      )
    }
  }

  return (
    <Page fullWidth>
      <Navigation />
      {loading ?
      <Skeleton />
      :
      renderContent()
      }
      {alert}
    </Page>
  );
}
  
function Skeleton() {
  return (
    <SkeletonPage fullWidth primaryAction>
      <Grid>
        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 4, xl: 4}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 8, xl: 8}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
      </Grid>
    </SkeletonPage>
  );
}

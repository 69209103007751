import axios from "axios";

export const getAppData = async (shop:string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_END_POINT}/Frontend/GetShop?shop=${shop}`,
  );
  const { data } = response;
  const shopID = data.shop.ID;
  const jwt = data.jwt;
  const timezone = data.timezoneName;

  localStorage.setItem("jwt", jwt);
  localStorage.setItem("shopID", shopID);
  localStorage.setItem("shop", shop);
  localStorage.setItem("timezone", timezone);
};
import {
  BlockStack,
  Card,
  Grid,
  Page,
  Text,
  Link,
  Button,
  ButtonGroup,
  InlineStack
} from "@shopify/polaris";
import { useState } from 'react';
import Progress from "../../components/progress/Progress";
import { PROGRESS } from "../../dto";
import type { StepProps } from "./create-pixel";
import Step1Banner from '../../assets/img/tiktok-banner.png'
import { checkEmbededApp } from "../../api/manage-pixel";
import PluginChat from "../../config/pluginChat";


export default function Step1(props: StepProps) {
  const [errorNotEmbeded, setErrorNotEmbeded] = useState<boolean | null>(null)    
  const [needHelp, setneedHelp] = useState<boolean>(true)    

  const submitStep1 = async () => {
    const response = await checkEmbededApp();
    if (response.data) {
      props.setProgress(PROGRESS._25)
    } else {
      setErrorNotEmbeded(false);
      window.open(`https://${localStorage.getItem('shop')}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_APPEMBEDID}/madgic-tiktokevent`, '_blank')
    }
  }

  return (
    <Page fullWidth>
      <Grid>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 2, lg: 4, xl: 4}}>
          <Progress progress={props.progress} onClick={()=>{submitStep1();}}/>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 4, lg: 8, xl: 8}}>
          <Card >
            <Text as="h2" variant="headingLg">Action Required: Enable Madgic Pixel embed on your theme</Text>
            <div className="py-2">
              <Text as="p" fontWeight="semibold">
                Complete this step by enabling Madgic on your{" "}
                <span style={{color: 'var(--p-color-text-link)', fontWeight: '500'}}>
                  <Link 
                    monochrome
                    target='_blank'
                    url={`https://${localStorage.getItem('shop')}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_APPEMBEDID}/madgic-tiktokevent`}
                  >
                    Shopify theme editor
                  </Link>
                </span>
              
              </Text>
            </div>
            <BlockStack>
              <div className="step1-img">
                <img src={Step1Banner} alt="Step1Img"/>
              </div>
            </BlockStack>
            <div className="py-3">
              <BlockStack align="center" inlineAlign="end">
                <ButtonGroup>
                  <Button onClick={() => {props.setProgress(PROGRESS._25) ;PluginChat.triggerEvent('tiktokpixel-needhelp-onboarding-1');}}>Enable Later</Button>
                  <Button variant="primary" onClick={() => submitStep1()}>Next Step</Button>
                </ButtonGroup>
              </BlockStack>
            </div>
           {errorNotEmbeded === false &&  
           <div className="my-2">
              <InlineStack align="end">
                <Text as="p" tone="critical">
                  Our app isn't actived correctly. <Link 
                    monochrome
                    target='_blank'
                    url={`https://${localStorage.getItem('shop')}/admin/themes/current/editor?context=apps&activateAppId=${process.env.REACT_APP_APPEMBEDID}/madgic-tiktokevent`}
                  >
                    Click here to open Shopify theme editor
                  </Link> or
                  {" "}<span style={{textDecoration: 'underline'}}>contact our support</span>{" "}
                  for assistance 
                </Text>
              </InlineStack>
            </div>
            }
          </Card>
        </Grid.Cell>
      </Grid>
    </Page>
  )
}
  
  
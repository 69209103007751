import {
    BlockStack,
    Card,
    Grid,
    Page,
    Text,
    Button,
    ButtonGroup,
    InlineStack,
    Link
  } from "@shopify/polaris";
  import type { Dispatch, SetStateAction } from "react";
  import { useNavigate } from "react-router-dom";
  import Progress from "../../components/progress/Progress";
  import Toturial from "../../components/toturial/Toturial";
  import type { ToturialDto, PROGRESS } from "../../dto";
  import FinishImg from '../../assets/img/finish.png';
  import TikTokLogo from '../../assets/img/titok-logo-sm.png';
  import ThumnailImg from '../../assets/img/toturial-thumbnail.png';
import PluginChat from "../../config/pluginChat";

  interface Step4Props {
    progress: number;
    setProgress: Dispatch<SetStateAction<PROGRESS>>;
    setAlreadyHavePixel: Dispatch<SetStateAction<boolean>>;
    resetState: () => void
  }

  const step3Toturial : ToturialDto[] = [
    {
    index: 1,
    title: 'How do I know if Tiktok Pixel is working?',
    des: 'Tiktok Pixel Helper is a Google Chrome extension from Tiktok that lets you test your pixel implementation. ',
    thumbnailUrl: ThumnailImg,
    link: 'https://www.youtube.com/watch?v=JXkgBNg_S6M&ab_channel=TikTokForBusiness',
    vidLength: 80,
   }
  ]
  
  export default function Step4(props: Step4Props) {
    const navigate = useNavigate()
    
    return (
      <Page fullWidth>
        <Grid>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 2, lg: 4, xl: 4}}>
            <Progress progress={props.progress} onClick={()=>{}}/>
            <Toturial 
              toturials={step3Toturial}
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 4, lg: 8, xl: 8}}>
            <Card >
              <InlineStack align="center">
                <div className="mr-2">
                  <img src={TikTokLogo} alt="tiktok-logo" />
                </div>
                <Text as="h2" variant="headingMd">How do I know if Tiktok Pixel is working?</Text>
              </InlineStack>
              <InlineStack align="center">
                <img src={FinishImg} alt="step4Img"/>
              </InlineStack>
              <InlineStack align="center">
                <div style={{width: '50%'}}>
                  <Text as="p">
                    TikTok Pixel Helper is a Google Chrome extension from Tiktok that lets you test your
                    pixel implementation. It is essentially a troubleshooting tool that tells you if all is well with your Tiktok pixel.
                    {" "}
                    <Link 
                      monochrome
                      target="_blank"
                      url="https://chrome.google.com/webstore/detail/tiktok-pixel-helper/aelgobmabdmlfmiblddjfnjodalhidnn"
                    >
                      Install The Tiktok Pixel Helper here
                    </Link>
                  </Text>
                </div>
              </InlineStack>
              <div className="py-3">
                <BlockStack align="center" inlineAlign="center">
                  <ButtonGroup>
                    <Button 
                      onClick={() => {
                        props.resetState();
                        props.setAlreadyHavePixel(true);
                      }}
                    >
                      Create another pixel
                      </Button>
                    <Button 
                      variant="primary"
                      onClick={() => {navigate('/pixel/manage-pixel'); PluginChat.triggerEvent('tiktokpixel-needhelp-onboarding-4');}}
                    >
                      Go to Manage Pixel
                    </Button>
                  </ButtonGroup>
                </BlockStack>
              </div>
            </Card>
          </Grid.Cell>
        </Grid>
      </Page>
    )
  }
  
  
export enum InfoPixelBufferType {
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  Purchase = 'Purchase'
}

export interface InfoPixelBuffer {
  Type: InfoPixelBufferType;
  Title: string;
  Datasets: (string|number)[];
  TotalEvents: number
}

export interface TimeZone {
  label: string;
  value: string;
  time: number
}

export interface PixelDashboard {
  Id: number;
  PixelID: string;
  Status: boolean;
}

export interface InsightDto {
  Description: string;
  Link: string;
  PostedBy: string;
  PostedDate: string;
  PostedDateStr: string;
  Title: string;
}

interface Partner {
  TabId: number;
  Link: string;
}

export const PARTNER_LIST: Partner[] = [
  {
    TabId: -1,
    Link: "https://apps.shopify.com/17track?utm_source=OrderLimits&utm_medium=OrderLimits&utm_campaign=20230404"
  },
  {
    TabId: -2,
    Link: "https://app.minea.com/find-winning-product?ref=alex15"
  },{
    TabId: -3,
    Link: "https://link.fordeer.io/wi8m3v"
  },
  {
    TabId: -4,
    Link: "https://pagefly.io/?ref=orichi&target=app-listing"
  },
  {
    TabId: -5,
    Link: "https://apps.shopify.com/kaktus-preorder?utm_medium=affiliate&utm_source=Orichi"
  }
]


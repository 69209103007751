import '../create-pixel/create-pixel.css'
import { useState, useEffect } from 'react';
import type { Dispatch, ReactElement, SetStateAction } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  Frame,
  Grid,
  Page,
  SkeletonBodyText,
  SkeletonPage,
  Toast,
} from "@shopify/polaris";
import Step2 from '../create-pixel/step2';
import { API_TYPE,
  type CollectionDto,
  type ProductDto,  
  PROGRESS,
  EVENT_API_TARGET,
  CreateStatus
} from '../../dto';
import { CreatePixelRequest, GetPixelByID } from '../../api/manage-pixel';
import { getGIDFromID, getIDFromGID } from '../../utils';
import Navigation from '../../components/navigation/Navigation';

export interface StepProps {
  progress: number;
  setProgress: Dispatch<SetStateAction<PROGRESS>>
}

export default function PixelDetail() {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const param = useParams();
  const navigate = useNavigate()

  const [progress, setProgress] = useState<PROGRESS>(PROGRESS._0)
  const [pixelTitle, setPixelTitle] = useState<string>("");
  const [pixelID, setPixelID] = useState<string>("");
  const [ID, setID] = useState<number>(0);
  const [selectedCollect, setSelectedCollect] = useState<CollectionDto[]>([]);
  const [selectedProduct, setSelectedtProduct] = useState<ProductDto[]>([]);
  const [apiType,setApiType] = useState<API_TYPE>(API_TYPE.BROWSER);
  const [selectedTarget, setSelectedTarget] = useState<EVENT_API_TARGET>(EVENT_API_TARGET.ENTIRE_STORE);
  const [isEnableStatus, setEnableStatus] = useState<boolean>(true);
  const [testCode, setTestCode] = useState<string>("");
  const [tokenAccess, setTokenAccess] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState<ReactElement | null>(null)


  const handleEditPixel = async () => {
    const payload = {
      FacebookPixel: pixelID,
      ID: ID,
      IsEnableConversationAPI: apiType === API_TYPE.EVENTS_API ? true : false,
      IsFirstCreate: false,
      ListCollectionName: selectedCollect.map(collection => collection.title).join('|||'),
      ListProductName: selectedProduct.map(product => product.title).join('|||'),
      LstCollect: selectedCollect.map(collection => getIDFromGID(collection.id) ).join(','),
      LstProduct: selectedProduct.map(product => getIDFromGID(product.id) ).join(','),
      Shop: String(localStorage.getItem('shop')),
      ShopID: Number(localStorage.getItem('shopID')),
      Status: isEnableStatus,
      Target: selectedTarget,
      TestCode: testCode,
      Title: pixelTitle,
      TokenAccess: tokenAccess
    }

    const response = await CreatePixelRequest(payload)
    if (response.isSuccess) {
      setAlert(
        <Frame>
          <Toast
            content={'Edit Success!'}
            onDismiss={() => setAlert(null)}
          />
        </Frame>
      )
      setTimeout(() => {
        navigate('/pixel/manage-pixel')
      }, 1000)
    } else {
      setAlert(
        <Frame>
          <Toast
            error
            content={response.Messenger || 'Something wrong, please try again'}
            onDismiss={() => setAlert(null)}
          />
        </Frame>
      )
    }
  }

  useEffect(() => {
    if (param.id)
    fetchPixelDetail(param.id);
  }, [param.id])

  const fetchPixelDetail = async (id: string) => {
    setLoading(true)
    const pixel = await GetPixelByID(id);
    if (pixel) {
      setPixelTitle(pixel.Title || "")
      setPixelID(pixel.FacebookPixel || "");
      setApiType(pixel.IsEnableConversationAPI ? API_TYPE.EVENTS_API : API_TYPE.BROWSER);
      setSelectedTarget(pixel.Target as EVENT_API_TARGET)
      setEnableStatus(pixel.Status)
      setTestCode(pixel.TestCode || "");
      setTokenAccess(pixel.TokenAccess || "");
      setID(pixel.ID)

      if (pixel.ListCollectionName && pixel.LstCollect) {
        const nameList = pixel.ListCollectionName.split('|||');
        const idList = pixel.LstCollect.split(',');
        const collections =  idList.map((id, index) => {
          return {
            id: getGIDFromID(id, EVENT_API_TARGET.COLLECTIONS),
            title: nameList[index]
          }
        })
        setSelectedCollect(collections)
      } else {
        setSelectedCollect([])
      }

      if (pixel.ListProductName && pixel.LstProduct) {
        const nameList = pixel.ListProductName.split('|||');
        const idList = pixel.LstProduct.split(',');
        const products =  idList.map((id, index) => {
          return {
            id: getGIDFromID(id, EVENT_API_TARGET.PRODUCTS),
            title: nameList[index]
          }
        })
        setSelectedtProduct(products)
      } else {
        setSelectedtProduct([])
      }
    }
    setLoading(false)
  }

  return (
    <Page fullWidth>
      <Navigation />
      {loading ?
      <Skeleton />
      :
      <Step2
          createStatus={CreateStatus.Edit}
          progress={progress}
          setProgress={setProgress}
          pixelTitle={pixelTitle}
          setPixelTitle={setPixelTitle}
          pixelID={pixelID}
          setPixelID={setPixelID}
          selectedCollect={selectedCollect}
          setSelectedCollect={setSelectedCollect}
          selectedProduct={selectedProduct}
          setSelectedtProduct={setSelectedtProduct}
          apiType={apiType}
          setApiType={setApiType}
          selectedTarget={selectedTarget}
          setSelectedTarget={setSelectedTarget}
          testCode={testCode}
          setTestCode={setTestCode}
          tokenAccess={tokenAccess}
          setTokenAccess={setTokenAccess}
          isEnableStatus={isEnableStatus}
          setEnableStatus={setEnableStatus}
          handleEditPixel={handleEditPixel}
        />
      }
      {alert}
    </Page>
  );
}

function Skeleton() {
  return (
    <SkeletonPage fullWidth primaryAction>
      <Grid>
        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 4, xl: 4}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{xs: 6, sm: 6, md: 6, lg: 8, xl: 8}}>
          <div className='loading-block' style={{height: '324px'}}>
            <Card>
              <SkeletonBodyText />
            </Card>
          </div>
        </Grid.Cell>
      </Grid>
    </SkeletonPage>
  );
}
  
import axios from 'axios';

// Singleton instance of Axios
const instance = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
});

instance.interceptors.request.use((req) => {
  const jwt = localStorage.getItem('jwt')
  if (jwt) {
    req.headers.Authorization = `Bearer ${jwt}`;
  }
  return req
})

export default instance;
